"use client";
import { Routes } from "@/@types/api/routes.enum";
import { Property } from "@/@types/global";
import { Session } from "@/config/auth";
import { getCookie, setCookie } from "@/lib/cookies";
import { fetcher } from "@/lib/fetcher";
import { useProperties } from "@/services/property";
import { useMutation } from "@tanstack/react-query";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { toast } from "sonner";

type Props = {
  data: Property;
};

export const useCardModel = ({ data }: Props) => {
  const { data: session } = useSession() as any
  const [fav, setFav] = useState(false)
  useEffect(() => {
    setFav(session?.customer?.favorites?.find((property: any) => property.propertyId === data.id) ?? false)
  }, [session, data.id])
  
  const mutation = useMutation({
    mutationFn: async (id: string) => {
      const method = fav ? 'DELETE' : 'POST'
      if(method == "DELETE") setFav(false)
      if(method == "POST") setFav(true)
      const url = `${Routes.Favorites}?id=${id}`
      return await fetcher(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${session?.token}`,
        },
      })
    },
    onSuccess: async (response) => {
      toast.success(`Favorito ${fav ? 'adicionado' : 'removido'} com sucesso!`)
    },
    onError: () => {
      toast.success(`Favorito ${!fav ? 'adicionado' : 'removido'} com sucesso!`)
      setFav(false) // Reverte estado em caso de erro
    }
  })

  const handleToggleFav = () => {
    if (!session?.token) {
      toast.error('Você precisa estar logado para adicionar favoritos.')
      return
    }
    mutation.mutate(data.id.toString())
  }

  const copyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/property/${data.slug}`)
      .then(() => toast.success("Link copiado com sucesso!"))
      .catch(err => console.error("Erro ao copiar link:", err))
  }

  return {
    handleToggleFav,
    copyLink,
    fav,
    session
  };
};
