"use client";
import { compareContext } from "@/contexts/compare-context";
import { Button } from "@/components/ui/button";
import { GitCompareArrows } from "lucide-react";
import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import type { Property } from "../../../../@types/global";

type Props = {
  data: Property;
};

export default function Compare({ data }: Props) {
  const [open, setOpen] = useState(false);
  const { add, property, remove } = compareContext();
  const route = useRouter();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleCompare = () => {
    route.push("/compare");
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  useEffect(() => {
    const clearHover = () => {
      setHoveredIndex(null);
    };
    document.addEventListener("mouseleave", clearHover);
    return () => {
      document.removeEventListener("mouseleave", clearHover);
    };
  }, []);

  return (
    <div className="relative z-20">
      <button
        onClick={() => setOpen(!open)}
        className="z-20 flex cursor-pointer gap-1 rounded-md bg-white p-1 hover:bg-zinc-100"
      >
        <GitCompareArrows size={16} />
        <p className="text-[12px]">Comparar</p>
      </button>

      {open && (
        <div className="absolute top-full -right-6 mt-1 md:w-80 w-[150px]  flex flex-col p-[15px] bg-white shadow-lg border rounded-md">
          <h1 className="text-base font-[500] text-center">Comparar imóveis</h1>
          <div className="scroll-x flex h-full w-full gap-2 p-4 pb-1 z-[500]">
            {property.map((property, index) => (
              <div key={property.id} className="relative">
                <img
                  alt={property?.title}
                  onClick={() => remove(property)}
                  src={property.attachments[0]}
                  width={200}
                  height={150}
                  className="w-[50px] h-[50px]"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                />
                {hoveredIndex === index && (
                  <div
                    className="absolute top-0 flex h-full w-full cursor-pointer items-center justify-center bg-red-300/60 text-center"
                    onClick={() => remove(property)}
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <p className="text-4xl text-red-600">X</p>
                  </div>
                )}
              </div>
            ))}
          </div>
          {!property.find((p) => p.id === data.id) && (
            <Button
              onClick={() => add(data)}
              className="mt-2 h-3 w-full bg-[#4279ca] p-3 text-white"
            >
              Adicionar
            </Button>
          )}
          {property.length > 1 && (
            <Button
              variant="default"
              className="mt-2 h-3 w-full p-3"
              onClick={handleCompare}
            >
              Comparar
            </Button>
          )}
					<Button onClick={() => setOpen(false)} className="mt-2 h-3 w-full p-3">Fechar</Button>
        </div>
      )}
    </div>
  );
}
