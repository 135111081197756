'use client'
import { Libraries, useJsApiLoader } from "@react-google-maps/api";
export const googleMapsLibraries: Libraries = ["places", "marker", "geometry"];

const GoogleMapsLoader = () => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
    libraries: googleMapsLibraries as Libraries,
    region: "BR",
    language: "pt-BR",
  });
  
  return { isLoaded, loadError };
};

export default GoogleMapsLoader;