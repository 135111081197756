import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { FAQS } from "@/constant/copyrights";
import { ChevronDown, ChevronUp } from "lucide-react";
import Link from "next/link";
import { useState } from "react";

export default function Faq() {
	const [isOpen, setIsOpen] = useState(false);
	return (
		<>
			<div className="my-[90px] flex flex-col items-center justify-center gap-4 max-md:text-center">
				<h3 className="text-base font-[600] text-[#063b89]">FAQs</h3>
				<div className="flex items-center gap-2 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
					<h1 className="text-2xl font-bold text-black">Perguntas mais frequentes</h1>
					{isOpen ? <ChevronUp /> : <ChevronDown />}
				</div>
				{
					isOpen &&
				<p className="text-base text-foreground/60">
					Precisa de algo esclarecido? Aqui estão nossas perguntas mais
					frequentes.
				</p>
				}
			</div>
			{
				isOpen &&
				 <>
					<Accordion type="single" collapsible className="w-full">
					{
						FAQS.map((faq, index) => (
							<AccordionItem value={faq.answer} key={index}>
								<AccordionTrigger className="text-start">{index+1}.{"  "}{faq.question}</AccordionTrigger>
								<AccordionContent>
									{faq.answer}
								</AccordionContent>
							</AccordionItem>
						))
					}
				</Accordion>
				<div className="container mt-[60px] grid max-md:grid-cols-1 items-center justify-between gap-4 md:grid-cols-2 md:grid-rows-2">
					<div>
						<h3 className="text-xl font-bold">Ainda tem dúvidas?</h3>
						<p className="text-foreground/60">
							Não encontrou a resposta que procura? Converse com nossa equipe
							amigável.
						</p>
					</div>
					<div className="flex justify-end ">
						<Link href={"/lawyer/contact"} className="max-md:w-full">
							<Button className="flex-2 flex max-md:w-full">Entre em contato</Button>
						</Link>
					</div>
				</div>
			</>
			}
		</>
	);
}
