import useQueryParams from "@/hooks/use-params";
import { useModal } from "@/contexts/modal-provider";
import { useLocationStore } from "@/store/location";
import { zodResolver } from "@hookform/resolvers/zod";
import { useRouter } from "next/navigation";
import { RefObject, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import CustomModal from "../../../../../components/globals/custom-model";
import { OptionsFilterView } from "../../../../../components/globals/filter/options/options.view";
import { FilterOptionsInfoState } from "../../../../../components/globals/filter/options/options.types";
import GoogleMapsLoader from "@/config/api-loader";


const filter = z.object({
  location: z.string().optional(),
  priceRange: z.object({
    label: z.string(),
    values: z.tuple([z.number(), z.number()]),
  }),
});

type Props = {
  inputAutoComplete: RefObject<HTMLInputElement>;
};

export const useFilter = ({ inputAutoComplete }: Props) => {
  const router = useRouter()
  const { isLoaded } = GoogleMapsLoader();
  const { objToParams } = useQueryParams()
  const [isLoading] = useState(false);
  const { setOpen, setClose } = useModal();
  const params = new URLSearchParams();
  const { setLocation, location, setPlace } = useLocationStore()

  const form = useForm<z.infer<typeof filter>>({
    resolver: zodResolver(filter),
  });

  const onSubmit = (obj: FilterOptionsInfoState) => {
    const coords = {
      latitude: location?.lat,
      longitude: location?.lng
    }
    let params_ = objToParams(obj)
    if (coords.latitude !== undefined && coords.longitude !== undefined) {
      params_ = objToParams({ ...obj, latitude: coords.latitude, longitude: coords.longitude, address: "" });
    }
    router.push(`?${params_}`);
    setClose();
  };


  const handleOpen = () => {
    setOpen(
      <CustomModal subheading="Todos os filtros que deseja" className="max-w-[600px] w-full">
        <OptionsFilterView isLoading={isLoading} onSubmit={onSubmit} />
      </CustomModal>
    );
  };

  useEffect(() => {
    if (isLoaded) {
      const gAutoComplete = new google.maps.places.Autocomplete(
        inputAutoComplete.current as HTMLInputElement,
        {
          types: ["geocode"],
          componentRestrictions: { country: "BR" },
        }
      );
      gAutoComplete.addListener("place_changed", () => {
        const place = gAutoComplete.getPlace();
        if (place.geometry?.location) {
          const location = place.geometry.location.toJSON();
          const address = place.formatted_address || "";
          setPlace(place);
          setLocation(location);
          params.set("lat", location.lat.toString());
          params.set("lng", location.lng.toString());
          params.set("address", address);
        }
      });
    }
  }, [isLoaded, inputAutoComplete, setPlace]);

  return {
    onSubmit,
    handleOpen,
    form,
    inputAutoComplete,
  }
}