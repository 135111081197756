import {
  Ban,
  CirclePlus,
  CreditCard,
  Heart,
  Mail,
  RefreshCw,
} from 'lucide-react'

export const FAQS = [
  {
    icon: Heart,
    question: 'O que é o MINB?',
    answer: `O MINB é a plataforma que reúne todos os imóveis em um só lugar e que ajuda particulares, imobiliárias e corretores de todo o Brasil a impulsionarem suas vendas.`,
  },
  {
    icon: RefreshCw,
    question: 'O MINB vende imóveis?',
    answer: `NÃO, o MINB não é uma imobiliária. Somos um portal de anúncios de alta visibilidade que auxilia particulares, imobiliárias e corretores a potencializarem sua atividade, e usuários a encontrarem o imóvel desejado.`,
  },
  {
    icon: Ban,
    question: 'O MINB participa de alguma negociação?',
    answer: `Não, o MINB apenas anuncia imóveis, direcionando potenciais clientes diretamente às
imobiliárias, corretores e proprietários, sem qualquer interferência. Não cobramos ou
recebemos comissão o qualquer tipo de remuneração proveniente da venda, locação
ou outra modalidade de negócio realizada entre as partes.`,
  },
  {
    icon: CirclePlus,
    question: 'O MINB é seguro?',
    answer: `SIM totalmente seguro, o usuário ao selecionar o imóvel desejado, é direcionado
diretamente ao anunciante, seja ele imobiliária, corretor ou proprietário.`,
  },
  {
    icon: CreditCard,
    question: 'Quem pode anunciar no MINB?',
    answer: `Imobiliárias, corretores independentes e proprietários de imóveis, todos denominamos
de anunciantes.
`,
  },
  {
    icon: Mail,
    question:
      'Consigo comprar, vender ou alugar um imóvel diretamente com o MINB?',
    answer: `NÃO, é impossível, não somos corretores de imóveis. Não existe esse canal de acesso entre clientes e o MINB.`,
  },
  {
    icon: Mail,
    question: `Como conseguir abaixar o preço de um imóvel anunciado no MINB?`,
    answer: `NÃO, não somos uma imobiliária, e não corretamos nenhuma atividade imobiliária.`,
  },

  {
    icon: Mail,
    question: `O MINB é gratuito? / Anunciar no MINB é gratuito?`,
    answer: `Diretamente com o anunciante, não alteramos nenhuma informação ou dados dos
imóveis dos anunciantes. Os anúncios são copias fiéis de como estão anunciados na
origem.`,
  },

  {
    icon: Mail,
    question: `Como cadastrar?`,
    answer: `Sim, o MINB é uma plataforma 100% gratuita cujo objetivo principal é facilitar e
acelerar o encontro entre clientes, imobiliárias, corretores e proprietários.
Apenas caso queira que seu imóvel seja destacado é que haverá cobrança.`,
  },

  {
    icon: Mail,
    question: `Saiba como funciona o "ENCOMENDAR`,
    answer: `Crie sua conta clicando em &quot;Login&quot; e, em seguida, em &quot;Cadastrar&quot;. Preencha seus
dados, selecione o tipo de conta e pronto! Você já pode anunciar a quantidade de
imóveis que desejar.`,
  },
  {
    icon: Mail,
    question: `Corretores, tenham um site gratuito / SITE GRATUITO no MINB para corretores`,
    answer: `Para os corretores que desejam ter um site gratuito para apresentar seus imóveis a milhares de potenciais clientes, o MINB oferece uma página exclusiva onde todos os seus imóveis estarão disponíveis.`,
  },
  {
    icon: Mail,
    question: `MINB me ajuda a administrar os meus anúncios?`,
    answer: `Para os corretores que desejam ter um site gratuito para apresentar seus imóveis a milhares de potenciais clientes, o MINB oferece uma página exclusiva onde todos os seus imóveis estarão disponíveis.`,
  },
  {
    icon: Mail,
    question: `Porque utilizar o MINB?`,
    answer: `O MINB reúne todos os imóveis em um só lugar. Chega de perder tempo procurando
em vários sites diferentes. No MINB você tem uma experiência única navegando
através do mapa, e dispõe de filtros que te ajudam a encontrar o imóvel desejado que
esteja publicado por imobiliárias e corretores em todo o Brasil.`
  },
]

export const TERMS = [
  {
    title: '1. Uso do Site',
    body: 'O MINB.com.br fornece informações sobre imóveis para locação e aluguel. Os usuários concordam em utilizar o site de maneira legal e em conformidade com todas as leis aplicáveis.',
  },
  {
    title: '2. Registro de Conta',
    body: 'Alguns recursos do site podem exigir que os usuários se registrem para obter uma conta. Os usuários são responsáveis por manter suas informações de conta atualizadas e seguras.',
  },
  {
    title: '3. Conteúdo do Usuário',
    body: 'Os usuários concordam em não publicar, transmitir ou compartilhar conteúdo ofensivo, difamatório, ilegal ou prejudicial. O MINB.com.br se reserva o direito de remover qualquer conteúdo inadequado.',
  },
  {
    title: '4. Propriedade Intelectual',
    body: 'O conteúdo, logotipos e design do MINB.com.br são protegidos por direitos autorais e outras leis de propriedade intelectual. Os usuários concordam em não copiar, reproduzir ou distribuir esse conteúdo sem permissão.',
  },
  {
    title: '5. Responsabilidade',
    body: 'O MINB.com.br não se responsabiliza por informações imprecisas ou incompletas em listagens de propriedades. Os usuários devem verificar todas as informações diretamente com os anunciantes.',
  },
  {
    title: '6. Alterações nos Termos',
    body: 'Reservamos o direito de fazer alterações nestes Termos a qualquer momento. As alterações serão publicadas no site com uma data de revisão. O uso continuado do site após alterações constitui a aceitação dos novos Termos.',
  },
  {
    title: '7. Encerramento de Conta',
    body: 'Reservamos o direito de encerrar contas de usuários que violem estes Termos.',
  },
  {
    title: '8. Lei Aplicável',
    body: `Estes Termos são regidos pelas leis do Brasil. Qualquer disputa relacionada a estes Termos será resolvida nos tribunais competentes no Brasil. Ao utilizar o MINB.com.br, os usuários concordam em cumprir estes Termos e a Política de Privacidade associada. Se você não concorda com esses termos, por favor, não use o site. Se você tiver alguma dúvida ou preocupação, entre em contato conosco em Inserir informações de contato`,
  },
]

export const POLICY_PRIVACY = [
  { 
    title: '1. Informações Coletadas',
    body: 'Coletamos informações pessoais de nossos usuários, como nome, endereço de e-mail, número de telefone e detalhes relacionados a propriedades de aluguel ou locação. Essas informações são fornecidas voluntariamente pelos usuários ao utilizar nosso site, preencher formulários de contato ou se comunicar conosco.',
  },
  { 
    title: '2. Uso das Informações',
    body: 'Utilizamos as informações coletadas para: – Fornecer serviços personalizados, como pesquisas de imóveis e recomendações. – Facilitar a comunicação entre usuários e anunciantes de propriedades. – Melhorar e personalizar a experiência do usuário no site. – Envio de comunicações relacionadas a serviços, atualizações ou ofertas. – Cumprir obrigações legais e regulatórias.',
  },
  { 
    title: '3. Compartilhamento de Informações',
    body: 'Não vendemos, alugamos ou compartilhamos informações pessoais com terceiros, a menos que seja necessário para fornecer serviços específicos ou atender a requisitos legais.',
  },
  { 
    title: '4. Segurança das Informações',
    body: 'Adotamos medidas de segurança para proteger as informações dos usuários, incluindo criptografia de dados e acesso restrito a informações pessoais. No entanto, a segurança de informações transmitidas pela internet não pode ser garantida.',
  },
  { 
    title: '5. Cookies e Tecnologias Semelhantes',
    body: 'Utilizamos cookies e tecnologias similares para melhorar a experiência do usuário, analisar o uso do site e fornecer publicidade direcionada. Os usuários podem gerenciar as configurações de cookies em seus navegadores.',
  },
  { 
    title: '6. Links para Sites de Terceiros',
    body: 'Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelas práticas de privacidade ou conteúdo desses sites. Recomendamos que os usuários revisem as políticas de privacidade desses sites antes de fornecer qualquer informação pessoal.',
  },
  { 
    title: '7. Menores de Idade',
    body: 'Nosso site não é direcionado a menores de 18 anos. Não coletamos intencionalmente informações de menores de idade. Se tivermos conhecimento de que coletamos informações pessoais de menores, tomaremos medidas para excluí-las.',
  },
  { 
    title: '8. Alterações na Política de Privacidade',
    body: 'Reservamos o direito de fazer alterações nesta Política de Privacidade a qualquer momento. As alterações serão publicadas no site com uma data de revisão. É responsabilidade dos usuários revisar periodicamente esta Política.',
  }
]

export const COUNTRIES = [
  { name: 'Brazil', code: 'BR', dial_code: '+55' },
  { name: 'Canada', code: 'CA', dial_code: '+1' },
  { name: 'United Kingdom', code: 'GB', dial_code: '+44' },
  { name: 'Australia', code: 'AU', dial_code: '+61' },
  { name: 'Germany', code: 'DE', dial_code: '+49' },
  { name: 'France', code: 'FR', dial_code: '+33' },
  { name: 'India', code: 'IN', dial_code: '+91' },
  { name: 'Japan', code: 'JP', dial_code: '+81' },
  { name: 'China', code: 'CN', dial_code: '+86' },
  { name: 'South Africa', code: 'ZA', dial_code: '+27' },
  { name: 'Mexico', code: 'MX', dial_code: '+52' },
  { name: 'Argentina', code: 'AR', dial_code: '+54' },
  { name: 'Italy', code: 'IT', dial_code: '+39' },
  { name: 'Spain', code: 'ES', dial_code: '+34' },
]
