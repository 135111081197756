"use client"
import { Property } from "@/@types/global"
import useQueryParams from "@/hooks/use-params"
import { getPropertiesCloseToMe, getPropertiesInfiniteScroll } from "@/services/property"
import { useLocationStore } from "@/store/location"
import { useRef, useState } from "react"

export const useHome = () => {
  const { getParam, params: searchParams } = useQueryParams()
  const params = new URLSearchParams(searchParams);
  const { location } = useLocationStore()
  const [propertiesVisible] = useState<Property[]>([])
  const [zoom, setZoom] = useState(12)
  const [page, setPage] = useState(Number.parseInt(getParam('pag') || '1'))
  const refCloseMap = useRef<HTMLDivElement>(null)
  const mapInput = useRef<HTMLInputElement>(null)
  const propertiesScroll = getPropertiesInfiniteScroll({ zoom, maps: true })
  const { propertiesCloseToMe } = getPropertiesCloseToMe()

  const updatePage = (newPage: number) => {
    setPage(newPage)
    params.set("page", newPage.toString());
  }
  const handleSelectChange = (value: string) => {
    const [order, field] = value.split(' ');
    const address = params.get("address");
  
    params.set("sortBy", field);
    params.set("sortDir", order);
  
    if (!address) {
      params.delete("latitude");
      params.delete("longitude");
    }
  
    const newUrl = `${window.location.pathname}?${params.toString()}`;
  
    window.history.pushState({}, "", newUrl);
  };

  return {
    handleSelectChange,
    page,
    propertiesCloseToMe,
    propertiesVisible,
    refCloseMap,
    mapInput,
    zoom,
    setZoom,
    updatePage,
    location,
    propertiesScroll,
  }
}