import { Swiper } from "swiper/react"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import { ReactNode } from "react";

type Props = {
  children: ReactNode
}
export const InfiniteScrollView = ({ children }: Props) => {
  const breakPoints = {
    400: { slidesPerView: 1, spaceBetween: 20 },
    650: { slidesPerView: 2, spaceBetween: 20 },
    1200: { slidesPerView: 3.5, spaceBetween: 30 },
  };
  return (
    <Swiper
      className="w-full"
      spaceBetween={30}
      slidesPerView={1.5}
      allowSlideNext={true}
      allowSlidePrev={true}
      breakpoints={breakPoints}
    >
      {children}
    </Swiper>
  );
};