"use client";
import useQueryParams from "@/hooks/use-params";
import { useClusterContext } from "@/components/providers/clusters-provider";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { Property } from "../@types/global";
import { QueryKeys } from "@/constant/query-key";
import { Routes } from "@/@types/api/routes.enum";
import { fetcher } from "@/lib/fetcher";
import { returnIdsLimitedParams } from "@/lib/utils";

type PropsUseProperties = {
  size?: number;
  pag?: number;
  params?: string;
};

export const useProperties = ({ pag = 1, size = 12 }: PropsUseProperties) => {
  const { params } = useQueryParams();
  return useQuery({
    queryKey: QueryKeys.Properties(params),
    queryFn: async () => {
      const url = `${Routes.PropertiesPag}?size=${size}&pag=${pag}&sortBy=price${params && `&${params}`}`
      const response = await fetcher(url)
      return response
    },
    staleTime: 10 * (60 * 1000),
  });
};

export const getPropertiesCloseToMe = () => {
  try {
    const json = localStorage.getItem("ipbase");
    if(json === null) return { propertiesCloseToMe: [] };
    const { lat, lng } = JSON.parse(json);
    const { data } = useQuery({
      queryKey: QueryKeys.Properties(lat, lng),
      queryFn: async () => await fetcher(`${Routes.PropertiesPag}?latitude=${lat}&longitude=${lng}&radius=10&size=30&pag=1`),
      staleTime: 7 * (60 * 1000),
    });
  
    return {
      propertiesCloseToMe: data ?? [],
    };
  } catch (error) {
    return {
      propertiesCloseToMe: [],
    };
  }
};



export const getPropertiesBySlug = (slug: string) => {
  return useQuery<Property>({
    queryKey: QueryKeys.Properties(slug),
    queryFn: async () => {
      const response = await fetcher(`${Routes.PropertiesIdoOrSlug}?slug=${slug}`)
      return response
    },
  });
};

export const getPropertiesById = (id: number) => {
  return useQuery<Property>({
    queryKey: QueryKeys.Properties(id),
    queryFn: async () => {
      const response = await fetcher(`${Routes.PropertiesIdoOrSlug}?radius=4&id=${id}`)
      return response
    },
  });
};

type GetPropertiesInfiniteScrollProps = {
  zoom?: number;
  maps?: boolean;
};

export const getPropertiesInfiniteScroll = ({ zoom = 12, maps = false }: GetPropertiesInfiniteScrollProps) => {
  const { params } = useQueryParams();

  return useInfiniteQuery({
    queryKey: QueryKeys.Properties(params),
    queryFn: async ({ pageParam = 1 }) => {
      let url = `${Routes.PropertiesPag}?size=12&radius=20&pag=${pageParam}&${params}`;
      return await fetcher(url);
    },
    getNextPageParam: (lastPage, allPages) => {
      const size = 12; 
      const data = lastPage?.data || [];
      if (data.length < size) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
    staleTime: 0,
  });
};

export const getPropertiesInfiniteScrollIds = () => {
  const { ids } = useClusterContext();

  return useInfiniteQuery({
    queryKey: ids.length ? [`ids`, ...ids] : [`ids`], 
    queryFn: async ({ pageParam = 1 }) => {
      let _ids = returnIdsLimitedParams(ids, pageParam - 1);
      if (!_ids || ids.length === 0) {
        return new Promise(() => {}); // Faz a query "aguardar" indefinidamente
      }
      let url = `${Routes.PropertiesPag}?size=12&${_ids}`;
      return await fetcher(url);
    },
    getNextPageParam: (lastPage, allPages) => {
      const size = 12; 
      const data = lastPage?.data || [];
      if (data.length < size) return undefined;
      return allPages.length + 1;
    },
    initialPageParam: 1,
    staleTime: 0,
    enabled: !!ids && ids.length > 0, 
  });
};



export const getPropertiesMap = () => {
  const { params } = useQueryParams();
  return useQuery({
    queryKey: QueryKeys.Map(params),
    queryFn: async () => {
      const output = await fetcher(`${Routes.PropertiesMap}?radius=20&${params}`)
      return output.data
    },
  });
};
